import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Selection } from 'clientside-coupon';
import { OddsLayoutResolverService } from '@kingmakers-tech/mobile-virtuals-soccer';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { VirtualsCouponSelectionService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon-selections.service';
import { fadeIn, preventInitialChildAnimations, slideInOut } from 'src/app/shared/animations';
import { OddModel, OddsByMarket } from 'src/app/shared/models/coupon.model';
import { InstantCouponService } from 'src/app/core/services/instant-coupon/instant-coupon.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';

enum SelectionItemStatus {
  Idle,
  Loading,
  AnimatingOut,
  Edit,
  AnimatingIn,
  Table,
  NoOddsForEdit,
}

interface SelectionItemState {
  status: SelectionItemStatus;
  selections?: Selection[];
}

enum SelectionItemTransition {
  NoOddsFound,
  ShowEdit,
  HideEdit,
  AnimationDone,
}

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'app-virtuals-instant-coupon-selection-item',
  templateUrl: './virtuals-instant-coupon-selection-item.component.html',
  styleUrls: ['./virtuals-instant-coupon-selection-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInOut(), fadeIn(), preventInitialChildAnimations()],
})
export class VirtualsInstantCouponSelectionItemComponent {
  @Input() matchName: string;
  @Input() marketSelections: OddsByMarket;
  @Input() smartBetCode: number;
  @Input() matchOddsLength: number;

  private readonly initialState: SelectionItemState = {
    status: SelectionItemStatus.Idle,
  };

  private readonly state = new BehaviorSubject<SelectionItemState>(this.initialState);
  state$ = this.state.asObservable();
  status$ = this.state$.pipe(
    map(state => state.status),
    distinctUntilChanged()
  );

  selections$ = this.state$.pipe(
    map(state => state.selections),
    distinctUntilChanged()
  );

  layout$ = this.selections$.pipe(map(selections => this.layoutResolver.resolveLayout(selections.map(this.mapToSelection))));

  oddsForEdit$ = this.selections$.pipe(
    map(selections =>
      selections.map(selection => ({
        selection: this.mapToSelection(selection),
        odd: selection,
      }))
    )
  );

  showEdit$ = this.status$.pipe(map(status => status === SelectionItemStatus.Edit));
  showOddsTable$ = this.status$.pipe(map(status => [SelectionItemStatus.Edit, SelectionItemStatus.AnimatingOut].indexOf(status) === -1));
  isLoading$ = this.status$.pipe(map(status => status === SelectionItemStatus.Loading));
  noOddsForEdit$ = this.status$.pipe(map(status => status === SelectionItemStatus.NoOddsForEdit));
  isSingleLine$ = this.layout$.pipe(map(layout => layout === 'single'));

  constructor(
    private readonly virtualsCouponSelectionsService: VirtualsCouponSelectionService,
    private readonly virtualsCouponService: InstantCouponService,
    private readonly layoutResolver: OddsLayoutResolverService,
    private readonly appConfig: AppConfigService
  ) {}

  handleSlideDone(): void {
    this.transitionState(SelectionItemTransition.AnimationDone);
  }

  removeOdd(selectionId: number): void {
    this.virtualsCouponService.removeOdd(selectionId);
  }

  oddTrackBy(index: number, item: OddModel): number {
    return item.id;
  }

  getOddsForEdit(): void {
    this.virtualsCouponSelectionsService
      .getMarketSelections(this.smartBetCode, this.marketSelections.marketId)
      .pipe(catchError(() => of([])))
      .subscribe(odds => {
        odds.length > 0
          ? this.transitionState(SelectionItemTransition.ShowEdit, { selections: odds })
          : this.transitionState(SelectionItemTransition.NoOddsFound);
      });
  }

  selectionCount(marketId: number, matchOdds: any): boolean {
    let numOfSelections: number = 0;

    matchOdds.forEach(odd => {
      if (odd.marketId === marketId) {
        numOfSelections = numOfSelections + 1;
      }
    });

    return numOfSelections === 1;
  }

  handleEditClick(): void {
    switch (this.state.value.status) {
      case SelectionItemStatus.Idle:
      case SelectionItemStatus.Table:
        this.transitionState(SelectionItemTransition.ShowEdit);
        break;
      case SelectionItemStatus.Edit:
        this.transitionState(SelectionItemTransition.HideEdit);
        break;
      default:
        return;
    }
  }

  parseSelectionName(selectionName: string, marketId: string, marketName: string): string {
    const winnerMarketId = this.appConfig.get('virtuals').instantLeague.winnerMarketId;

    const teams = marketName.split(' - ');
    const homeTeam = teams[0];
    const awayTeam = teams.length > 1 ? teams[1] : undefined;

    if (marketId === winnerMarketId) {
      switch (selectionName.toLowerCase().trim()) {
        case '1':
          return homeTeam;
        case 'x':
          return $localize`Draw`;
        case '2':
          return awayTeam;
        default:
          return selectionName;
      }
    } else {
      return selectionName;
    }
  }

  private readonly mapToSelection = selection => ({
    selectionId: selection.selectionTypeId,
    selectionName: selection.selectionName,
    shortCut: selection.selectionShortcut,
  });

  private transitionState(transition: SelectionItemTransition, payload?: Partial<SelectionItemState>): void {
    this.state.next(this.reducer(this.state.value, transition, payload));
  }

  /* eslint-disable default-case */
  private readonly reducer = (
    state: SelectionItemState,
    transition: SelectionItemTransition,
    payload: Partial<SelectionItemState> = {}
  ) => {
    switch (state.status) {
      case SelectionItemStatus.Idle: {
        switch (transition) {
          case SelectionItemTransition.ShowEdit:
            // ideally side effects shouldn't be handled , if we have to add more we have to extract it
            this.getOddsForEdit();
            return { ...state, status: SelectionItemStatus.Loading };
        }
        break;
      }

      case SelectionItemStatus.Table: {
        switch (transition) {
          case SelectionItemTransition.ShowEdit:
            return { ...state, status: SelectionItemStatus.AnimatingOut };
        }
        break;
      }

      case SelectionItemStatus.Edit: {
        switch (transition) {
          case SelectionItemTransition.HideEdit:
            return { ...state, status: SelectionItemStatus.AnimatingIn };
        }
        break;
      }

      case SelectionItemStatus.Loading: {
        switch (transition) {
          case SelectionItemTransition.ShowEdit:
            return { ...state, status: SelectionItemStatus.AnimatingOut, ...payload };
          case SelectionItemTransition.NoOddsFound:
            return { ...state, status: SelectionItemStatus.NoOddsForEdit };
        }
        break;
      }

      case SelectionItemStatus.AnimatingOut: {
        switch (transition) {
          case SelectionItemTransition.AnimationDone:
            return { ...state, status: SelectionItemStatus.Edit };
        }
        break;
      }

      case SelectionItemStatus.AnimatingIn: {
        switch (transition) {
          case SelectionItemTransition.AnimationDone:
            return { ...state, status: SelectionItemStatus.Table };
        }
        break;
      }
    }

    return state;
  };
}
