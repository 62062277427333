import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { APIType } from 'src/app/shared/models/api.model';
import { BonusList, CurrencySetting, InstantUser, OnlineHashRequestBody } from 'src/app/shared/models/virtuals.model';
import { LocalStorageService } from 'ngx-webstorage';
import { InstantLeagueWSClient } from '@kingmakers-tech/mobile-virtuals-soccer';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { LoggerService } from 'src/app/core/services/logger.service';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AccumulatorBonusStore } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.store';
import { DataLayerEvent, DataLayerProduct } from 'src/app/shared/models/datalayer.model';
import { format } from 'date-fns';
import { APIService } from './api.service';
import { AppConfigService } from './app-config.service';
import { DataLayerService } from './data-layer.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class VirtualsInstantService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly instantLeagueConfig = this.appConfig.get('virtuals').instantLeague;
  private readonly whitelistedUrls = this.instantLeagueConfig.webSocketActiveUrls;
  private readonly instantUserDataKey = this.instantLeagueConfig.userDataStorageKey;

  constructor(
    private readonly apiService: APIService,
    private readonly virtualsStore: VirtualsStore,
    private readonly virtualsQuery: VirtualsQuery,
    private readonly localStorage: LocalStorageService,
    private readonly wsClient: InstantLeagueWSClient,
    private readonly accountQuery: AccountQuery,
    private readonly appConfig: AppConfigService,
    private readonly loggerService: LoggerService,
    private readonly notificationService: NotificationService,
    private readonly dataLayerService: DataLayerService,
    private readonly accumulatorBonusStore: AccumulatorBonusStore
  ) {}

  loginToGR(): Observable<any> {
    return this.getOnlineHashForForUser().pipe(
      switchMap(onlineHash => this.loginToGRWithOnlineHash(onlineHash)),
      takeUntil(this.destroy$)
    );
  }

  syncGRSession(): Observable<any> {
    return this.wsRequest('GET', '/session/sync').pipe(
      catchError(error => {
        return throwError(error);
      }),
      takeUntil(this.destroy$)
    );
  }

  getDataFromGR(endpoint: string, params: any = {}): Observable<any> {
    return this.callGREndpoint('GET', endpoint, undefined, params);
  }

  postDataToGR(endpoint: string, body: any, params: any = {}): Observable<any> {
    return this.callGREndpoint('POST', endpoint, body, params);
  }

  getInstantLeagueMarketMapping(): Observable<void> {
    // Retrieve the data only once
    return this.virtualsQuery.instantLeagueMap
      ? of(undefined)
      : this.apiService.get(APIType.CMS, '/Virtuals/GetVirtualsInstantLeagueMap').pipe(
          map(response => {
            if (response) {
              this.virtualsStore.updateInstantLeagueMap(response);
            }
          }),
          takeUntil(this.destroy$)
        );
  }

  manageWebSocketConnection(urlSplit): void {
    const whitelistedUrl = urlSplit.filter(url => this.whitelistedUrls.includes(url));

    if (whitelistedUrl.length > 0) {
      this.wsClient.connectToWebSocket();
      !this.localStorage.retrieve(this.instantUserDataKey) && this.loginToGR();
    } else {
      this.wsClient.disconnectFromWebsocket();
    }
  }

  createDataLayerEvent(dataLayerEvent: DataLayerEvent): void {
    this.dataLayerService.createDataLayerEvent({
      event: dataLayerEvent.event,
      product: DataLayerProduct.VirtualsInstant,
      userId: this.accountQuery.userData?.id,
      uniqueUserIdentifier: dataLayerEvent.uniqueUserIdentifier,
      time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      gameCode: dataLayerEvent.gameCode,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private callGREndpoint(requestType: 'GET' | 'POST', endpoint: string, body: any, params: any): Observable<any> {
    const loginAndCallEndpoint$ = this.loginToGR().pipe(
      switchMap(() => {
        // If user is still not logged in, log an error
        if (!this.virtualsQuery.instantUserData?.id) {
          this.loggerService.logEvent(
            `Unable to call GR websocket endpoint '${endpoint}' without online hash`,
            undefined,
            SeverityLevel.Error
          );
          return of(undefined);
        }
        return this.wsRequest(requestType, endpoint, body, params);
      }),
      takeUntil(this.destroy$)
    );

    if (!this.virtualsQuery.instantUserData?.id) {
      // If online hash not available, login user first
      return loginAndCallEndpoint$;
    } else {
      return this.wsRequest(requestType, endpoint, body, params).pipe(
        switchMap(response => {
          if (response?.errorCode) {
            if (response.errorCode === '421') {
              // EXPIRED_SESSION - Login the user and attempt the WS call again
              return loginAndCallEndpoint$;
            } else {
              this.loggerService.logEvent(
                `GR websocket endpoint '${endpoint}' returned an error`,
                JSON.stringify(response),
                SeverityLevel.Error
              );
              return of(response);
            }
          }
          return of(response);
        }),
        takeUntil(this.destroy$)
      );
    }
  }

  private getOnlineHashForForUser(): Observable<string> {
    const body: OnlineHashRequestBody = {
      userId: this.accountQuery.userData?.id,
      bearerToken: this.accountQuery.accessToken,
    };

    return this.apiService.post(APIType.Website, `virtuals/api/GamingVendors/VirtualOnDemand/OnlineHash`, body).pipe(
      catchError(err => {
        this.notificationService.showInfoNotification($localize`Please try again later`, $localize`Game could not be loaded.`);
        this.dataLayerService.createDataLayerEvent({
          event: 'user-failed-authentication',
          product: DataLayerProduct.VirtualsInstant,
          userId: this.accountQuery.userData?.id,
          errorMessage: err.message,
        });

        return throwError(err.message);
      }),
      takeUntil(this.destroy$)
    );
  }

  private loginToGRWithOnlineHash(onlineHash: string): Observable<void> {
    if (this.wsClient.isConnectionClosed) {
      this.wsClient.connectToWebSocket();
    }

    return this.wsClient
      .get(`/session/loginOnlineHash?onlineHash=${onlineHash}&profiles=MOBILE`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .pipe(
        map((responseData: any) => {
          if (responseData?.errorCode) {
            this.notificationService.showInfoNotification(
              $localize`Please try again later (${responseData.errorCode})`,
              $localize`Game could not be loaded.`
            );
            this.dataLayerService.createDataLayerEvent({
              event: 'game-not-loaded',
              product: DataLayerProduct.VirtualsInstant,
              userOnlineHash: onlineHash,
              errorCode: responseData.errorCode,
            });
          }
          this.saveUserToLocalStorage(responseData);
        }),
        catchError((err: HttpErrorResponse) => {
          this.loggerService.logEvent('Unable to authenticate to GR with online hash', err.message, SeverityLevel.Error);
          return throwError(err.message);
        }),
        takeUntil(this.destroy$)
      );
  }

  private wsRequest(requestType: 'GET' | 'POST', endpoint: string, body: any = undefined, params: any = {}): Observable<any> {
    const clientId = this.virtualsQuery.instantUserData?.id;
    const headers = {
      'Content-Type': 'application/json',
      clientId: clientId,
    };

    if (requestType === 'GET') {
      return this.wsClient.get<any>(endpoint, { headers, params }).pipe(takeUntil(this.destroy$));
    } else {
      return this.wsClient.post<any>(endpoint, body, { headers, params }).pipe(takeUntil(this.destroy$));
    }
  }

  private saveUserToLocalStorage(user): void {
    const userOnDemand: InstantUser = {
      id: user.clientId,
      unitId: user.auth.unit.id,
      calculationId: user.calculationId,
      walletId: user.sessionStatus.wallets[0]?.walletId || null,
      tagsId: user.tagsId,
      userLimits: this.mapCurrencySettingFromApi(user).userLimits,
      name: user.auth.unit.name,
      extId: user.auth.unit.extId,
      extData: null,
      status: user.auth.unit.status,
      displays: user.gameContext.displays,
      userBonusList: this.mapCurrencySettingFromApi(user).userBonusList,
    };
    this.virtualsStore.updateInstantUserData(userOnDemand);
    this.accumulatorBonusStore.updateBonusList(this.virtualsQuery.instantUserData?.userBonusList);
  }

  private mapCurrencySettingFromApi(res): CurrencySetting {
    const currencySetting = res.calculationContext.ticketContext.currencySetting;
    const defaultCurrency = this.appConfig.get('virtuals')?.scheduledLeague.defaultCurrency || 'NGN';
    const currencySettingCurrentCountry = currencySetting.filter(currency => currency.key === defaultCurrency);

    return {
      userLimits: {
        maxPayout: currencySettingCurrentCountry[0]?.limits.ticket.maxPayout,
        maxStake: currencySettingCurrentCountry[0]?.limits.ticket.maxStake || undefined,
        minStake: currencySettingCurrentCountry[0]?.limits.ticket.minStake || undefined,
        minGroupingsBetStake: currencySettingCurrentCountry[0]?.limits.combi10.minStake || undefined,
        maxGroupingsBetStake: currencySettingCurrentCountry[0]?.limits.combi10.maxStake || undefined,
      },
      userBonusList: this.parseUserBonusList(currencySettingCurrentCountry[0]?.limits),
    };
  }

  private parseUserBonusList(limits): BonusList[] {
    return [
      { NumberOfEvents: 3, Percentage: this.parsePercentage(limits.combi3?.combiBonus) },
      { NumberOfEvents: 4, Percentage: this.parsePercentage(limits.combi4?.combiBonus) },
      { NumberOfEvents: 5, Percentage: this.parsePercentage(limits.combi5?.combiBonus) },
      { NumberOfEvents: 6, Percentage: this.parsePercentage(limits.combi6?.combiBonus) },
      { NumberOfEvents: 7, Percentage: this.parsePercentage(limits.combi7?.combiBonus) },
      { NumberOfEvents: 8, Percentage: this.parsePercentage(limits.combi8?.combiBonus) },
      { NumberOfEvents: 9, Percentage: this.parsePercentage(limits.combi9?.combiBonus) },
      { NumberOfEvents: 10, Percentage: this.parsePercentage(limits.combi10?.combiBonus) },
      { NumberOfEvents: 11, Percentage: 0 },
    ];
  }
  private parsePercentage(combiBonus): number {
    return Math.round(combiBonus * 100 - 100);
  }
}
