import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import localeAm from '@angular/common/locales/am';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CountrySelectionComponent } from 'src/app/components/app-country-selection/app-country-selection.component';
import { MiniSlideUpComponent } from 'src/app/components/app-mini-slide-up/mini-slide-up.component';
import { SiteUnavailableComponent } from 'src/app/components/app-site-unavailable/app-site-unavailable.component';
import { AccountsModule } from 'src/app/modules/accounts/accounts.module';
import { MetaLoader, MetaModule, MetaStaticLoader, PageTitlePositioning } from 'src/app/modules/meta/meta.module';
import { MobileVerificationCampaignModule } from 'src/app/modules/mobile-verification-campaign/mobile-verification-campaign.module';
import { QuickCouponModule } from 'src/app/modules/quick-coupon/quick-coupon.module';
import { brandInfo } from 'src/brand-info';
import { INSTANT_LEAGUE_WS_CONFIG, InstantLeagueWSConfig, InstantLeagueWSModule } from '@kingmakers-tech/mobile-virtuals-soccer';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MenuModule } from 'src/app/modules/menu/menu.module';
import { XSellModule } from 'src/app/modules/x-sell/x-sell.module';
import { VirtualsCouponModule } from 'src/app/modules/virtuals/modules/virtuals-coupon/virtuals-coupon.module';
import { VirtualsInstantCouponModule } from 'src/app/modules/virtuals/modules/virtuals-instant-coupon/virtuals-instant-coupon.module';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { createPersistedQueryLink } from 'apollo-angular/persisted-queries';
import sha256 from 'crypto-js/sha256';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SlideUpComponent } from './components/app-slide-up/slide-up.component';
import { WrapperComponent } from './components/app-wrapper/wrapper.component';
import { CoreModule } from './core/core.module';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { HttpOAuth2Interceptor } from './core/interceptors/http-oauth2.interceptor';
import { CouponModule } from './modules/coupon/coupon.module';
import { MyBetsModule } from './modules/my-bets/my-bets.module';
import { NativeAppModule } from './modules/native-app/native-app.module';
import { SharedModule } from './shared/shared.module';

// All locales need to have their data registered here so that they can be used in SSR by formatting pipes as necessary
registerLocaleData(localeAm);

export const metaDefaultsFactory = (): MetaLoader =>
  new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' - ',
    applicationName: `${brandInfo.brandName} ${brandInfo.country}`,
    defaults: brandInfo.metaDataDefaults,
  });

// Note we need a separate function as it's required by the AOT compiler.
// This will be loaded on demand
export const playerFactory = () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');

// using a separate function for AoT, that doesn't likes arrow syntax expressions in @NgModule
export const provideSwal = (): any => import('sweetalert2').then(({ default: Swal }) => Swal.mixin());

export const loadInstantLeagueWSConfig = (appConfig: AppConfigService): InstantLeagueWSConfig => {
  const instantLeagueConfig: any = appConfig.get('virtuals').instantLeague;
  return {
    enabled: instantLeagueConfig?.enabled,
    ...instantLeagueConfig?.webSocketConfig,
  };
};

export const loadApollo = (httpLink: HttpLink, appConfig: AppConfigService) => {
  const strapiCMSBaseUrl = appConfig.get('apiBaseUrl').strapiCms;
  if (strapiCMSBaseUrl) {
    const persistedQueriesLink = createPersistedQueryLink({
      useGETForHashedQueries: true,
      sha256: input => {
        return sha256(input).toString();
      },
    });
    return {
      cache: new InMemoryCache({
        addTypename: false,
      }),
      link: persistedQueriesLink.concat(
        httpLink.create({
          uri: `${appConfig.get('apiBaseUrl').strapiCms}/graphql`,
          // Workaround for Apollo CSRF protection
          headers: new HttpHeaders().set('VND.noAuthToken', 'true').set('apollo-require-preflight', 'true'),
        })
      ),
    };
  } else {
    console.error('No strapi base url - No CMS');
    return null;
  }
};

@NgModule({
  declarations: [
    AppComponent,
    WrapperComponent,
    SlideUpComponent,
    MiniSlideUpComponent,
    CountrySelectionComponent,
    SiteUnavailableComponent,
  ],
  imports: [
    // angular modules
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HammerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OverlayModule,

    // 3rd party modules
    NgxWebstorageModule.forRoot({ prefix: 'n', separator: '_', caseSensitive: true }),
    SweetAlert2Module.forRoot({ provideSwal }),
    LottieModule.forRoot({ player: playerFactory }),
    ApolloModule,
    ApmModule,

    // project modules
    AppRoutingModule,
    CoreModule,
    SharedModule,
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaDefaultsFactory,
    }),
    AccountsModule,
    CouponModule,
    QuickCouponModule,
    MyBetsModule,
    LottieCacheModule.forRoot(),
    MobileVerificationCampaignModule,
    NativeAppModule,
    InstantLeagueWSModule.forRoot(),
    MenuModule,
    XSellModule,
    VirtualsCouponModule,
    VirtualsInstantCouponModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpOAuth2Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: INSTANT_LEAGUE_WS_CONFIG, useFactory: loadInstantLeagueWSConfig, deps: [AppConfigService] },
    { provide: APOLLO_OPTIONS, useFactory: loadApollo, deps: [HttpLink, AppConfigService] },
    ApmService,
    { provide: ErrorHandler, useClass: ApmErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
