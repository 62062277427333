import { Injectable, OnDestroy, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import {
  BetCoupon,
  BetCouponGlobalVariable,
  Bonus,
  ClientsideCouponService,
  CouponAction,
  Dictionary,
  Selection,
  CouponType,
  UpdateCouponRequest,
  UpdateCouponResponse,
} from 'clientside-coupon';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponStakeHandlerService } from 'src/app/core/services/coupon/coupon-stake-handler.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { AccumulatorBonusStore } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.store';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { LiveQuery } from 'src/app/core/state/live/live.query';
import { LiveStore } from 'src/app/core/state/live/live.store';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { SportsbookFreeBetService } from 'src/app/modules/freebets/services/sportsbook-free-bet.service';
import { MyBetsService } from 'src/app/modules/my-bets/services/my-bets.service';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import {
  BookedCoupon,
  CouponGroupingType,
  CouponOddsModel,
  CouponUIState,
  ExpiredEvents,
  ExpiredEventsModel,
  OddChanges,
  OddModel,
  BestSellerModel,
  BestSellerOddModel,
} from 'src/app/shared/models/coupon.model';
import { MarketTypeIdsModel, QuicklinkModel } from 'src/app/shared/models/sport.model';
import { INSERT_COUPON_STATUS_CODES, UPDATE_COUPON_STATUS_CODES } from 'src/app/shared/utils/coupon-status-codes';
import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';
import { AdjustEvents, BetslipActions, DataLayerProduct, FirebaseEvent } from 'src/app/shared/models/datalayer.model';
import { CouponFlexicutService } from 'src/app/core/services/coupon/coupon-flexicut.service';
import { ClientsideCouponWrapperService } from 'src/app/core/services/coupon/clientside-coupon-wrapper.service';
import { CouponGroupingsService } from 'src/app/core/services/coupon/coupon-groupings.service';
import { CookieService } from 'src/app/core/services/cookie.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ABTestService } from 'src/app/core/services/ab-test.service';
import { BOOKING_CODE_KEY } from 'src/app/shared/utils/local-storage-keys';

const EventBasedProduct = {
  l: DataLayerProduct.SportsBookLive, // Live
  f: DataLayerProduct.SportsBookPrematch, // Prematch
  m: DataLayerProduct.SportsBookMixed, // Both live & prematch
};

const BetslipProduct = (acc: string, curr) => {
  const previous = acc.trim().toLowerCase();
  const current = (curr.EventCategory || '').trim().toLowerCase();
  return previous === current ? previous : 'm';
};

const BetslipSports = (acc: string, curr) => {
  const previous = acc.trim().toLowerCase();
  const current = (curr.SportName || '').trim().toLowerCase();
  return previous === current ? previous : 'mixed';
};
const BetslipTournaments = (acc: string, curr) => {
  const previous = acc.trim().toLowerCase();
  const current = (curr.TournamentName || '').trim().toLowerCase();
  return previous === current ? previous : 'mixed';
};

const RAS_ERROR_CODES = [600, 601];
@Injectable({
  providedIn: 'root',
})
export class CouponService implements OnDestroy {
  loading: boolean = false;
  enforceSingleCombination: boolean = false;
  allowCompleteDeselectOfEventOdds: boolean = true;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly accumulatorBonusStore: AccumulatorBonusStore,
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly applicationService: ApplicationService,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly clientsideCouponWrapperService: ClientsideCouponWrapperService,
    private readonly couponFlexicutService: CouponFlexicutService,
    private readonly couponGroupingsService: CouponGroupingsService,
    private readonly couponQuery: CouponQuery,
    private readonly couponStakeHandlerService: CouponStakeHandlerService,
    private readonly couponStore: CouponStore,
    private readonly dataLayerService: DataLayerService,
    private readonly evaluationService: EvaluationService,
    private readonly freebetService: SportsbookFreeBetService,
    private readonly liveQuery: LiveQuery,
    private readonly liveStore: LiveStore,
    private readonly loadingService: LoadingService,
    private readonly myBetsService: MyBetsService,
    private readonly notificationService: NotificationService,
    private readonly sportStore: SportStore,
    private readonly router: Router,
    private readonly cookieService: CookieService,
    private readonly languageService: LanguageService,
    private readonly abTestService: ABTestService
  ) {}

  get currencySymbol(): string {
    return this.accountQuery.userData && this.accountQuery.userData.currency.symbol
      ? this.accountQuery.userData.currency.symbol
      : this.appConfig.get('sports').coupon.defaultCurrency;
  }

  initialize(): void {
    if (this.couponQuery.couponInitialized) {
      // Get bonus list, because we are storing bonus list for sports/virtuals/instant at one place in the store
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/bonuslist`).subscribe(bonusListData => {
        if (bonusListData !== undefined) {
          const bonusList: Bonus[] = bonusListData;
          this.accumulatorBonusStore.updateBonusList(bonusList);
        }
      });
    } else {
      this.populateSportsbookVariables()
        .pipe(first())
        .subscribe(() => {
          this.couponStore.updateCouponInitialized(true);
          this.accumulatorBonusStore.updateIsSportsBonusListInit(true);
        });
    }

    // If logged out, reset stale time so that freebet vouchers are retrieved next time
    this.accountQuery.isAuthenticated$
      .pipe(
        filter(isAuth => !isAuth),
        tap(() => this.freebetService.resetVouchersandGetUserVouchersStaleTime()),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Set SportsBook as active Free Bet Product
    this.freebetService.setActiveFreeBetProduct(FreeBetProductType.SportsBook);
  }

  rebetCoupon(couponCode: string, language: string = 'en'): Observable<any> {
    return this.apiService.get(APIType.SportsbookBetsearch, `rebet/byCode/${couponCode}/language/${language}`).pipe(
      map(data => {
        if (data.BetCoupon) {
          data.BetCoupon.MaxPercentageBonus = isNaN(data.BetCoupon.MaxBonusPerc) ? 0 : data.BetCoupon.MaxBonusPerc;
          data.BetCoupon.MinPercentageBonus = isNaN(data.BetCoupon.MinBonusPerc) ? 0 : data.BetCoupon.MinBonusPerc;
          delete data.BetCoupon.MaxBonusPerc;
          delete data.BetCoupon.MinBonusPerc;

          const updatedCoupon = this.clientsideCouponService.formatCoupon(data.BetCoupon);
          this.couponStore.updateCouponData(updatedCoupon);

          this.applicationService.showCoupon();
          return data;
        } else {
          return false;
        }
      })
    );
  }

  fetchEmptyBetslipData() {
    const requestForQuicklinksIfEmpty = (bestSellers: any) => {
      if (!bestSellers || bestSellers.length === 0) {
        return this.apiService
          .gqlQuery<{ sportsbookPage: { data: { attributes: { emptyBetslipQuicklinks: QuicklinkModel[] } } } }>(
            `
          query SportsbookPageContent($locale: I18NLocaleCode) {
            sportsbookPage(locale: $locale) {
              data {
                attributes {
                  emptyBetslipQuicklinks {
                    title
                    url
                  }
                }
              }
            }
          }
          `,
            this.languageService.strapiCMSLocale
          )
          .pipe(
            catchError(err => {
              return of({
                data: { sportsbookPage: { data: { attributes: { emptyBetslipQuicklinks: [] } } } },
              });
            }),
            map(response => {
              const data = !!response.data && response.data;
              const quicklinks = data?.sportsbookPage?.data?.attributes?.emptyBetslipQuicklinks;
              this.couponStore.updateEmptyBetslipQuicklinks(quicklinks);
              return quicklinks;
            })
          );
      }
      return of({});
    };

    this.fetchBestSellers().pipe(switchMap(requestForQuicklinksIfEmpty)).subscribe();
  }

  fetchBestSellers(): Observable<BestSellerModel[]> {
    return this.apiService.get(APIType.SportsbookBetting2, 'bestSellers/CurrentSuperPicks').pipe(
      map(response => {
        if (response.ResponseStatus === 0 && response.SuperPicksBestSellerCoupons?.length > 0) {
          const parsedCoupons = response.SuperPicksBestSellerCoupons.map(coupon => {
            return {
              bestSellerName: coupon.BestSellerName,
              idBestSeller: coupon.IDBestSeller,
              potentialWinnings: coupon.PotentialWinnings,
              idCouponType: coupon.IDCouponType,
              odds: coupon.Odds.map(odd => {
                return new BestSellerOddModel({
                  EventName: odd.EventName,
                  HomeTeam: odd.HomeTeam,
                  AwayTeam: odd.AwayTeam,
                  MarketName: odd.MarketName,
                  SelectionName: odd.SelectionName,
                  OddValue: odd.OddValue,
                  IDSelection: odd.IDSelection,
                  UnboostedOddValue: odd.UnboostedOddValue,
                  IsBetBuilder: odd.IsBetBuilder,
                });
              }),
              stake: coupon.Stake,
              totalOdds: coupon.TotalOdds,
              unboostedTotalOdds: coupon.UnboostedTotalOdds,
              bestSellerStartDate: coupon.BestSellerStartDate,
              bookedCouponCode: coupon.BookedCouponCode,
              bookingCount: coupon.BookingCount,
            } as BestSellerModel;
          });
          this.couponStore.updateBestSellers(parsedCoupons);
          return parsedCoupons;
        }
        return [];
      }),
      catchError(err => {
        return of([]);
      })
    );
  }

  isOddInCoupon(oddId: number): boolean {
    if (this.couponQuery.couponData === undefined || this.couponQuery.couponData.Odds === undefined) {
      return false;
    }
    return this.couponQuery.couponData.Odds.findIndex(o => o.SelectionId === oddId) > -1;
  }

  addOdd(odd: OddModel, language: string = 'en'): UpdateCouponResponse {
    if (this.appConfig.get('live').useServerSideCoupon) {
      this.addOddServerSide(odd, language).subscribe(res => res.success && localStorage.removeItem(`n_${BOOKING_CODE_KEY}`));
      return new UpdateCouponResponse({});
    } else {
      const response = this.addOddClientSide(odd);
      response.success && localStorage.removeItem(`n_${BOOKING_CODE_KEY}`);
      return response;
    }
  }

  removeOdd(oddId: number, marketId?: number): UpdateCouponResponse {
    let proceedWithRemoval = true;
    if (!this.allowCompleteDeselectOfEventOdds) {
      // See whether this market has any selections left in the coupon
      proceedWithRemoval = this.couponQuery.couponData.Odds.filter(o => o.MarketId === marketId).length > 1;
    }

    if (proceedWithRemoval) {
      this.couponStore.clearGroupingTab();

      const selection = new Selection();
      selection.oddId = oddId;

      const response = this.clientsideCouponWrapperService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.RemoveOdd,
          brandID: this.appConfig.get('brandId'),
          coupon: this.couponQuery.couponData,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.couponQuery.globalVariables,
          marketExceptions: this.couponQuery.marketExceptions,
          correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
          selection: selection,
        })
      );

      this.couponStore.updateCouponData(response.updatedCoupon);

      this.removeFromOddChanges(oddId);
      this.liveStore.removeLiveAreaSelectionIds(oddId);

      this.applicationService.showQuickCoupon(false);

      this.couponStore.updateInvalidFreebetSelections(
        this.couponQuery.getInvalidFreebetSelections().filter(invalidSelection => invalidSelection !== oddId)
      );

      this.couponStore.updateInvalidFlexicutSelections(
        this.couponQuery.getInvalidFlexicutSelections().filter(invalidSelection => invalidSelection !== oddId)
      );

      response.success && localStorage.removeItem(`n_${BOOKING_CODE_KEY}`);

      return response;
    } else {
      return undefined;
    }
  }

  removeOdds(oddIds: number[]): UpdateCouponResponse {
    let updatedCoupon: BetCoupon = this.couponQuery.couponData;
    let allOddsRemoved: boolean = true;

    this.couponStore.clearGroupingTab();

    oddIds.forEach(oddId => {
      const selection = new Selection();
      selection.oddId = oddId;

      const response = this.clientsideCouponWrapperService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.RemoveOdd,
          brandID: this.appConfig.get('brandId'),
          coupon: updatedCoupon,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.couponQuery.globalVariables,
          marketExceptions: this.couponQuery.marketExceptions,
          correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
          selection: selection,
        })
      );

      updatedCoupon = response.updatedCoupon;
      if (response.success) {
        this.removeFromOddChanges(oddId);
        this.liveStore.removeLiveAreaSelectionIds(oddId);
      } else {
        allOddsRemoved = false;
      }
    });

    this.couponStore.updateCouponData(updatedCoupon);
    this.applicationService.showQuickCoupon(false);

    this.couponStore.updateInvalidFreebetSelections(
      this.couponQuery.getInvalidFreebetSelections().filter(invalidSelection => !oddIds.some(odd => odd === invalidSelection))
    );

    this.couponStore.updateInvalidFlexicutSelections(
      this.couponQuery.getInvalidFlexicutSelections().filter(invalidSelection => !oddIds.some(odd => odd === invalidSelection))
    );

    return new UpdateCouponResponse({
      success: allOddsRemoved,
      updatedCoupon: updatedCoupon,
    });
  }

  removeFromOddChanges(oddId: number): void {
    this.couponStore.removeFromOddChanges(oddId);
  }

  updateOddBankerStatus(oddId: number, isBanker: boolean): UpdateCouponResponse {
    const selection = new Selection();
    selection.oddId = oddId;

    const response = this.clientsideCouponWrapperService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateOddBankerStatus,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        selection: selection,
        isBanker: isBanker,
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  clearAllBankers(): UpdateCouponResponse {
    const response = this.clientsideCouponWrapperService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ClearAllBankers,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix,
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  validateCoupon(): UpdateCouponResponse {
    const response = this.clientsideCouponWrapperService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ValidateCoupon,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix,
      })
    );

    return response;
  }

  validateAndPostCoupon(isQuickCoupon: boolean = false): Observable<boolean> {
    if (this.couponQuery.couponData === null) {
      return of(false);
    }

    const validation = this.validateCoupon();

    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      this.addBetSubmittedEvent(this.couponQuery.couponData, isQuickCoupon);
      return this.postCoupon();
    }
  }

  validateAndPostBookCoupon(): Observable<any> {
    const userId = this.accountQuery.userData?.id;
    if (this.couponQuery.couponData === null) {
      return of(false);
    }

    const bookedCouponCount = this.couponQuery.bookedCoupons.length;
    const maxNumberOfBookedBets = 20;

    if (bookedCouponCount >= maxNumberOfBookedBets) {
      const errorMessage = $localize`Maximum number of booked bets reached. Please remove previously booked bets.`;
      this.notificationService.showErrorNotification(errorMessage);
      this.dataLayerService.createDataLayerEvent({
        event: BetslipActions.BookBetFailure,
        error_message: errorMessage,
        user_id: userId,
      });
      return of(false);
    }

    const validation = this.validateCoupon();
    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      this.dataLayerService.createDataLayerEvent({
        event: BetslipActions.BookBetSubmitted,
        user_id: userId,
      });
      return this.postBookCoupon();
    }
  }

  updateUI(ui: CouponUIState): void {
    this.couponStore.update({ ui });
  }

  updateCouponSetting(couponSettingKey: string, couponSetting: any): void {
    const setting = this.couponQuery.couponSettings;
    setting[couponSettingKey] = couponSetting;
    this.couponStore.updateCouponSetting(setting);
  }

  clearCouponData(): void {
    this.couponStore.updateFlexicutSelectedOption(undefined);
    this.couponStore.updateFlexicutOdds(undefined);
    this.couponStore.clearCouponData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  removeBookedCoupon(couponCode: string): void {
    this.couponStore.removeBookedCoupon(couponCode);
  }

  updateCoupon(couponData: any): UpdateCouponResponse {
    const response = this.clientsideCouponWrapperService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ValidateCoupon,
        brandID: this.appConfig.get('brandId'),
        coupon: couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix,
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  updateExpiredEvents(expiredEvents: ExpiredEventsModel): void {
    this.couponStore.updateExpiredEvents(expiredEvents);
  }

  updateInvalidFreebetSelections(freebetDisabledSelections: number[]): void {
    this.couponStore.updateInvalidFreebetSelections(freebetDisabledSelections);
  }

  updateInvalidFlexicutSelections(flexicutDisabledSelections: number[]): void {
    this.couponStore.updateInvalidFlexicutSelections(flexicutDisabledSelections);
  }

  clearExpiredEvents(): void {
    this.couponStore.clearExpiredEvents();
  }

  getOddsChanged(couponOdds: CouponOddsModel[], language: string = 'en'): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    const bodyData = couponOdds;

    return this.apiService.post<any>(APIType.SportsbookBetting, `oddschanged/${language}`, bodyData, apiSettings).pipe(
      map(responseData => {
        if (!responseData || responseData.length === 0) {
          return;
        }

        // remove all expired odds from the coupon
        responseData
          .filter(o => o.IsExpired)
          .forEach(odd => {
            const expiredOdd = this.couponQuery.couponData.Odds.find(o => o.SelectionId === odd.SelectionID);
            if (expiredOdd) {
              const currentExpiredEvents = this.couponQuery.expiredEvents;
              this.updateExpiredEvents({
                availableEventCount: currentExpiredEvents
                  ? currentExpiredEvents.availableEventCount - 1
                  : this.couponQuery.couponData.Odds.length - 1,
                originalEventCount: currentExpiredEvents
                  ? currentExpiredEvents.originalEventCount
                  : this.couponQuery.couponData.Odds.length,
                bookedBetId: currentExpiredEvents?.bookedBetId,
                expiredEvents: [
                  ...(currentExpiredEvents ? currentExpiredEvents.expiredEvents : []),
                  {
                    eventId: expiredOdd.EventId,
                    smartBetCode: expiredOdd.SmartCode,
                    eventName: expiredOdd.MatchName,
                    eventDate: expiredOdd.EventDate,
                    eventCategory: expiredOdd.EventCategory,
                    marketName: expiredOdd.MarketName,
                    selectionName: expiredOdd.SelectionName,
                    oddValue: expiredOdd.OddValue,
                    marketTypeId: expiredOdd.MarketTypeId,
                    isBetBuilder: expiredOdd.IsBetBuilder,
                  },
                ],
              });
              this.removeOdd(expiredOdd.SelectionId);
            }
          });

        if (this.couponQuery.couponData?.Odds.length > 0) {
          // Update remaining odds
          const couponDataCopy: BetCoupon = cloneDeep(this.couponQuery.couponData);
          responseData
            .filter(o => !o.IsExpired)
            .forEach(changedOdd => {
              const couponOdd = couponDataCopy.Odds.find(o => o.SelectionId === changedOdd.SelectionID);
              if (couponOdd) {
                couponOdd.OddValue = changedOdd.SelectionValue;
                couponOdd.ConfirmedOddValue = changedOdd.SelectionValue;
                couponOdd.IsLocked = changedOdd.IsLocked;
                couponOdd.IsExpired = changedOdd.IsExpired;

                this.couponStore.updateOddChange(changedOdd.SelectionID, changedOdd.SelectionValue);
              }
            });
          this.couponStore.updateCouponData(couponDataCopy);

          if (this.couponQuery.isFlexicutApplicable) {
            // Force flexicut odds update
            this.couponFlexicutService.updateFlexicutOdds(couponDataCopy.Odds.map(odd => odd.OddValue));
          }

          // TODO:this has to be changed with a new clientside method.
          this.couponStakeHandlerService.updateStakeValue(this.couponQuery.couponData.StakeGross);
        } else {
          this.showExpiredNotification();
        }
      })
    );
  }

  acceptOddChanges(): void {
    const oddChangesCopy: OddChanges[] = cloneDeep(this.couponQuery.oddChanges);

    oddChangesCopy.forEach(o => {
      o.initialOddValue = o.latestOddValue;
      o.valueChanged = false;
    });

    this.couponStore.updateOddChanges(oddChangesCopy);
  }

  getInsertCouponStatus(statusCode: number): string {
    return INSERT_COUPON_STATUS_CODES[statusCode];
  }

  getUpdateCouponStatus(statusCode: number): string {
    return statusCode === 21 ? $localize`Over maximum winning amount` : UPDATE_COUPON_STATUS_CODES[statusCode];
  }

  showExpiredNotification(): void {
    let expiredMessage;
    const numOfExpiredEvents = this.couponQuery.expiredEvents.originalEventCount - this.couponQuery.expiredEvents.availableEventCount;
    if (this.couponQuery.couponData?.Odds.length > 0) {
      if (numOfExpiredEvents === 1) {
        expiredMessage = $localize`1 selection has expired. The selection has been removed from the Betslip.`;
      } else if (numOfExpiredEvents > 1) {
        const numOfExpired = this.couponQuery.expiredEvents.originalEventCount - this.couponQuery.expiredEvents.availableEventCount;
        expiredMessage = $localize`${numOfExpired} selections have expired and we have recalculated your returns accordingly.`;
      }
    } else {
      expiredMessage = $localize`All selections have expired. Please add new selections to your Betslip.`;
    }
    this.sendBetslipErrorEvent(expiredMessage);
    this.notificationService.showWarningMessage(expiredMessage, 5000, false);
  }

  createCouponFromSelectionIds(selectionIds: number[], stake?: number, language: string = 'en'): Observable<void> {
    this.loadingService.enqueueLoader();
    return this.apiService
      .post(
        APIType.SportsbookBetting2,
        `create/language/${language}`,
        stake
          ? {
              Selections: selectionIds.map(id => ({ SelectionId: id })),
              Stake: stake,
            }
          : {
              Selections: selectionIds.map(id => ({ SelectionId: id })),
            },
        new APISettings({
          noAuthToken: true,
        })
      )
      .pipe(
        map(responseData => {
          if (!responseData || responseData.length === 0 || !responseData.BetCoupon) {
            return;
          }

          this.couponStore.clearCouponData();

          const betCoupon = this.clientsideCouponService.formatCoupon(responseData.BetCoupon);
          this.couponStore.updateCouponData(betCoupon);
        }),
        finalize(() => {
          this.loadingService.dequeueLoader();
        })
      );
  }

  updatePreviousPage(path: string): void {
    this.couponStore.updatePreviousPage(path);
  }

  clearCouponExpiredOdds(): void {
    this.updateCoupon({
      ...this.couponQuery.couponData,
      Odds: this.couponQuery.couponData.Odds.filter(o => !o.IsExpired),
    });
  }

  clearLastPlacedCoupon(): void {
    this.couponStore.updateLastPlacedCoupon(undefined);
  }

  private addOddClientSide(odd: OddModel): UpdateCouponResponse {
    this.couponStore.clearGroupingTab();

    const response = this.clientsideCouponWrapperService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.AddOdd,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        selection: (odd.marketTypeId === this.couponQuery.preCannedBetBuilderMarketTypeID
          ? new OddModel({ ...odd, isBetBuilder: true })
          : odd
        ).toSelection(),
        allowSameMatchSelections: this.appConfig.get('sports').allowSameMatchSelections,
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);

    if (
      this.couponGroupingsService.isGroupingTypeVisible(CouponGroupingType.Split) &&
      this.couponQuery.couponData.CouponTypeId === CouponType.Flexicut
    ) {
      // Cannot have split bet Flexicut Coupons
      this.couponStore.updateFlexicutSelectedOption(undefined);
    }

    if (response.updatedCoupon.Odds.length === 1) {
      this.applicationService.showQuickCoupon(true, response.updatedCoupon.Odds[0]);
    } else {
      this.applicationService.showQuickCoupon(false);
    }

    return response;
  }

  private addOddServerSide(odd: OddModel, language: string = 'en'): Observable<UpdateCouponResponse> {
    // If allowSameMatchSelections is set to false and there is already an odd with the same
    // match of the new odd, remove the previous odd before calling the server
    if (!this.appConfig.get('sports').allowSameMatchSelections) {
      const prevSelection = this.couponQuery.getSameMatchSelection(odd.matchId);
      if (prevSelection) {
        this.removeOdd(prevSelection.SelectionId);
      }
    }

    const couponEmpty = this.couponQuery.couponData === undefined;
    const url = `live/${couponEmpty ? 'AddSelections' : 'AddSelectionsToCoupon'}/${language}?selectionIds[0]=${odd.id}`;
    const bodyData = this.couponQuery.couponData || {};
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });

    return this.apiService.post<any>(APIType.SportsbookBetting2, url, bodyData, apiSettings).pipe(
      map(responseData => {
        if (!responseData.BetCoupon) {
          return new UpdateCouponResponse({
            success: false,
            statusCode: responseData.ResponseStatus,
          });
        }

        responseData.BetCoupon.MaxPercentageBonus = isNaN(responseData.BetCoupon.MaxBonusPerc) ? 0 : responseData.BetCoupon.MaxBonusPerc;
        responseData.BetCoupon.MinPercentageBonus = isNaN(responseData.BetCoupon.MinBonusPerc) ? 0 : responseData.BetCoupon.MinBonusPerc;
        delete responseData.BetCoupon.MaxBonusPerc;
        delete responseData.BetCoupon.MinBonusPerc;

        const updatedCoupon = this.clientsideCouponService.formatCoupon(responseData.BetCoupon);
        this.couponStore.updateCouponData(updatedCoupon);
        const newOdd = updatedCoupon.Odds.find(o => o.SelectionId === odd.id);
        if (newOdd) {
          this.couponStore.addToOddChanges([{ oddId: odd.id, oddValue: newOdd.OddValue }]);
        }

        const selectedAreaInMatchView = this.liveQuery.selectedAreaInMatchView;
        if (selectedAreaInMatchView) {
          this.liveStore.updateLiveAreaSelectionIds({ [odd.id]: selectedAreaInMatchView.id });
        }

        if (updatedCoupon.Odds.length === 1) {
          this.applicationService.showQuickCoupon(true, updatedCoupon.Odds[0]);
        } else {
          this.applicationService.showQuickCoupon(false);
        }

        return new UpdateCouponResponse({
          success: true,
          updatedCoupon: updatedCoupon,
          statusCode: responseData.ResponseStatus,
        });
      })
    );
  }

  private postCoupon(): Observable<boolean> {
    this.loading = true;
    return this.syncCouponToServer().pipe(
      concatMap(syncResponse => {
        if (!syncResponse.success) {
          this.loading = false;
          this.handleErrorMessage(syncResponse.statusCode, syncResponse.errorCode);
          return of(false);
        }

        return this.insertCoupon().pipe(
          map(insertResponse => {
            this.loading = false;
            if (!insertResponse.success) {
              this.handleInsertCouponFailure(insertResponse);
              return false;
            }
            const betResponseStatus = insertResponse.statusCode;
            const coupon = insertResponse.updatedBetCoupon;

            if (betResponseStatus === 1) {
              // Coupon saved successfully.
              const inEvaluation = coupon !== undefined && coupon.CurrentEvalReason !== 0;

              this.couponStore.updateLastPlacedCoupon({
                ...insertResponse.updatedBetCoupon,
                BetDetails: {
                  ...insertResponse.updatedBetCoupon?.BetDetails,
                  FreeBetDetails: {
                    code: insertResponse.updatedBetCoupon?.BetDetails?.FreeBetDetails?.Code,
                    name: insertResponse.updatedBetCoupon?.BetDetails?.FreeBetDetails?.Name,
                    type: insertResponse.updatedBetCoupon?.BetDetails?.FreeBetDetails?.Type,
                  },
                },
                bookingCode: insertResponse.bookingCode,
                couponCode: insertResponse.couponCode,
              });

              if (inEvaluation) {
                coupon.CouponId = insertResponse.couponId;
                coupon.CouponCode = insertResponse.couponCode;
                coupon.CouponDate = insertResponse.couponDate;

                this.evaluationService.addToEvaluation(coupon);
              } else {
                this.accountService.updateBalance();
                this.myBetsService.addNewBet(insertResponse.couponCode);
                this.dataLayerService.createDataLayerEvent({
                  event: 'btk.betPlaced',
                  betPlaced: insertResponse.updatedBetCoupon.StakeGross,
                });
                this.dataLayerService.createDataLayerEvent({
                  event: 'user-place-bet',
                  userId: this.accountQuery.userData.id,
                  couponID: insertResponse.couponCode,
                  totalStake: insertResponse.updatedBetCoupon.StakeGross,
                });
              }

              this.freebetService.resetVouchersandGetUserVouchersStaleTime();
              this.freebetService.getUserVouchers().pipe(first(), takeUntil(this.destroy$)).subscribe();
              this.sendBetSuccessEvent(insertResponse);
            } else {
              if (coupon !== undefined) {
                this.updateCoupon(coupon);
              }
              const errorMessage: string = this.getInsertCouponStatus(betResponseStatus);
              this.sendBetFailureEvent(insertResponse, errorMessage);
              this.notificationService.showErrorNotification(errorMessage, $localize`Coupon Not Posted`);
            }
            return true;
          }),
          catchError(err => {
            // In case bet was still placed but for some reason call timed out, best check the current voucher status
            this.freebetService.getUserVouchers().pipe(first(), takeUntil(this.destroy$)).subscribe();
            return throwError(err);
          })
        );
      })
    );
  }

  private postBookCoupon(): Observable<any> {
    this.loading = true;

    return this.bookCoupon().pipe(
      map(bookResponse => {
        this.loading = false;
        if (!bookResponse.success) {
          const errorMessage = this.getInsertCouponStatus(bookResponse.statusCode);
          this.sendBookBetFailureEvent(errorMessage);
          this.notificationService.showErrorNotification(errorMessage, $localize`Coupon Not Booked`);
          return false;
        }

        const bookedCoupon = new BookedCoupon({
          couponCode: bookResponse.couponCode,
          date: format(new Date(), 'dd/MM/yy'),
        });

        this.couponStore.updateBookedCoupon(bookedCoupon);
        this.sendBookBetSuccessEvent(bookResponse.couponCode);
        return bookedCoupon;
      })
    );
  }

  private handleErrorMessage(statusCode: number, errorCode?: number): void {
    const errorMessage = this.getUpdateCouponStatus(statusCode);

    // Show error message according to the status code returned
    if (statusCode === 18) {
      // 18 => 'One of the chosen events has expired'. In that case we include the number of expired events
      this.showExpiredNotification();
    } else if (statusCode === 19) {
      // 19 => 'Stake under minimum amount allowed'. In that case we include the minimum stake amount
      const message = `${errorMessage} of ${this.couponStakeHandlerService.getFormattedMinStake()}`;
      this.sendBetslipErrorEvent(message);
      this.notificationService.showErrorNotification(message, $localize`Coupon Error`);
    } else if (statusCode === 22) {
      // 22 => 'Group stake under minimum amount allowed'. In that case we include the minimum group stake amount
      const minMessage = $localize`The minimum acceptable total stake is ${this.couponStakeHandlerService.getFormattedTotalStake()}.`;
      const message = `${errorMessage} of ${this.couponStakeHandlerService.getFormattedMinGroupStake()}. ${minMessage}`;
      this.sendBetslipErrorEvent(message);
      this.notificationService.showErrorNotification(message, $localize`Coupon Error`);
    } else if (statusCode === 201) {
      // Couldn't complete operation
      const message = this.getUpdateCouponStatus(errorCode);
      this.sendBetslipErrorEvent(message);
      this.notificationService.showErrorNotification(message, $localize`Coupon Error`);
    } else {
      this.sendBetslipErrorEvent(errorMessage);
      this.notificationService.showErrorNotification(
        errorMessage,
        statusCode === 21 ? $localize`Maximum Win Exceeded` : $localize`Coupon Error`
      );
    }
  }

  private handleInsertCouponFailure(response): void {
    const message: string = this.getInsertCouponStatus(response.statusCode);
    this.sendBetFailureEvent(response, message);
    if (response.statusCode === 16) {
      const userID = this.accountQuery.userData?.id;
      const depositCallback = () => {
        this.dataLayerService.createDataLayerEvent({ event: 'insufficient_modal_deposit_click', userID });
        window.location.href = '/account/payments/deposit';
      };
      const cancelDepositCallback = () => {
        this.dataLayerService.createDataLayerEvent({ event: 'insufficient_modal_close', userID });
        return true;
      };
      this.notificationService.showCustomNotification(
        message,
        'info',
        depositCallback,
        $localize`Deposit`,
        $localize`Insufficient balance`,
        cancelDepositCallback,
        $localize`Cancel`,
        false,
        'redesign',
        cancelDepositCallback
      );
    } else if (RAS_ERROR_CODES.includes(response.statusCode)) {
      this.notificationService.showErrorNotification(message, $localize`This bet could not be placed`);
    } else this.notificationService.showErrorNotification(message, $localize`Coupon Not Posted`);
  }

  private populateSportsbookVariables(): Observable<void> {
    const apiCalls = [
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/bonuslist`),
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/globalvariables`),
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/markettypeids`),
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/incompatiblemarketexceptions`),
    ];
    const correctScoreOddsMatrix = this.couponQuery.correctScoreOddsMatrix;
    if (
      !correctScoreOddsMatrix ||
      correctScoreOddsMatrix.cacheVersion !== this.appConfig.get('siteVersion') ||
      correctScoreOddsMatrix.data === undefined
    ) {
      apiCalls.push(this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/selectionCompatibilityMatrix`));
    }

    return forkJoin(apiCalls).pipe(
      map(([bonusListData, globalVariablesData, marketTypeIdsData, marketExceptionsData, correctScoreOddsMatrixData]) => {
        if (bonusListData !== undefined) {
          const bonusList: Bonus[] = bonusListData;
          this.accumulatorBonusStore.updateBonusList(bonusList);
        }

        if (globalVariablesData !== undefined) {
          const globalVariables: BetCouponGlobalVariable = globalVariablesData;
          this.couponStore.updateGlobalVariables(globalVariables);
          if (globalVariablesData.FlexiCutGlobalVariable?.Parameters) {
            this.couponFlexicutService.initFlexicutService({
              isInDevMode: isDevMode(),
              minSelections: globalVariablesData.MinFlexiCutSelections,
              oddsThreshold: globalVariablesData.FlexiCutGlobalVariable.Parameters.MinOddThreshold,
              minWinningSelections: globalVariablesData.FlexiCutGlobalVariable.Parameters.MinWinningSelections,
            });
          }
        }

        if (marketTypeIdsData !== undefined) {
          const marketTypeIdsModel: MarketTypeIdsModel[] = [];
          marketTypeIdsData.forEach(response => {
            const marketTypeIds = new MarketTypeIdsModel({
              marketTypeId: response.IDMarketType,
              lookupCode: response.LookupCode,
            });

            marketTypeIdsModel.push(marketTypeIds);
          });

          this.sportStore.updateMarketTypeIds(marketTypeIdsModel);
        }

        if (marketExceptionsData !== undefined) {
          const marketExceptions: Dictionary<number, number[]> = marketExceptionsData;
          this.couponStore.updateMarketExceptions(marketExceptions);
        }

        if (correctScoreOddsMatrixData !== undefined) {
          this.couponStore.updateCorrectScoreOddsMatrix({
            cacheVersion: this.appConfig.get('siteVersion'),
            data: correctScoreOddsMatrixData,
          });
        }
      })
    );
  }

  private syncCouponToServer(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    return this.apiService.put<any>(APIType.SportsbookBetting, 'UpdateCoupon', this.couponQuery.couponData, apiSettings).pipe(
      map(response => {
        let errorCode = 0;
        let couponSuccess = false;
        if (response.ResponseStatus === 0) {
          // BTK-1299: The following line has been temporarily commented out because the response returned by the api
          // doesn't yet match our model structure, resulting in lost/misplaced data
          // this.setCouponData(this.clientsideCouponService.formatCoupon(response.BetCoupon));

          // BTK-1419: Temporarily creating a copy of the OddValue field in a new field called ConfirmedOddValue.
          // This is usually done by the api but for now we have to do it manually due to the above commented out line
          const couponData = cloneDeep(this.couponQuery.couponData);
          couponData.Odds.forEach(odd => {
            odd.ConfirmedOddValue = odd.OddValue;
          });
          this.couponStore.updateCouponData(couponData);

          couponSuccess = true;
        } else if (response.ResponseStatus === 17) {
          // One of the odds has changed.
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }

          if (this.couponQuery.couponSettings.allowOddChanges) {
            couponSuccess = true;
          }
        } else if (response.ResponseStatus === 18) {
          // One of the chosen events has expired
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }

          if (response.ExpiredEvents !== undefined && response.ExpiredEvents.length > 0) {
            const expiredEventsModel = new ExpiredEventsModel({
              availableEventCount: response.AvailableEventCount,
              originalEventCount: response.OriginalEventCount,
              expiredEvents: response.ExpiredEvents.map(
                event =>
                  new ExpiredEvents({
                    eventId: event.IDEvent,
                    smartBetCode: event.SmartCode,
                    eventName: event.EventName,
                    eventDate: event.EventDate,
                    eventCategory: event.EventCategory,
                    marketName: event.MarketName,
                    selectionName: event.SelectionName,
                    oddValue: event.OddValue,
                    marketTypeId: event.MarketTypeId,
                    isBetBuilder: event.IsBetBuilder,
                  })
              ),
            });

            this.updateExpiredEvents(expiredEventsModel);
          }
        } else if (response.ResponseStatus === 201) {
          // Couldn't complete operation, check ErrorsList
          const errorsList = response.ErrorsList;
          if (errorsList[500]) {
            // Flexicut invalid selections
            errorCode = 500;
            this.updateInvalidFlexicutSelections(errorsList[500].map(selection => selection.IDSelection));
          }
          if (errorsList[416]) {
            // Freebet invalid selections
            errorCode = 416;
            this.updateInvalidFreebetSelections(response.ErrorsList[416].map(selection => selection.IDSelection));
          }
        } else {
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }
        }

        return {
          errorCode,
          success: couponSuccess,
          statusCode: response.ResponseStatus,
        };
      })
    );
  }

  private insertCoupon(): Observable<any> {
    return from(this.dataLayerService.getAdjustIds()).pipe(
      switchMap(adjustIds => {
        const apiSettings: APISettings = new APISettings({
          ...(adjustIds && {
            ...(adjustIds.adjustId && { adjustId: adjustIds.adjustId }),
            ...(adjustIds.gpsAdid && { gpsAdid: adjustIds.gpsAdid }),
            ...(adjustIds.idfa && { idfa: adjustIds.idfa }),
          }),
          inBehalfOf: this.couponQuery.couponSettings.transferUserId,
        });
        const bodyData = this.appConfig.get('sports').coupon.sendBookedCouponCode
          ? {
              AllowOddChanges: this.couponQuery.couponSettings.allowOddChanges,
              // Sportbook requested that a null value is sent - [SB-6184]
              BookedCouponCode: this.couponQuery.couponSettings.bookedCouponCode ? this.couponQuery.couponSettings.bookedCouponCode : null,
              BetCoupon: {
                ...this.couponQuery.couponData,
              },
              RequestTransactionId: this.clientsideCouponService.generateTransactionId(), // TODO: don't regenerate every time
              TransferStakeFromAgent: this.couponQuery.couponSettings.transferUserId === null ? false : true,
            }
          : {
              AllowOddChanges: this.couponQuery.couponSettings.allowOddChanges,
              BetCoupon: {
                ...this.couponQuery.couponData,
              },
              RequestTransactionId: this.clientsideCouponService.generateTransactionId(), // TODO: don't regenerate every time
              TransferStakeFromAgent: this.couponQuery.couponSettings.transferUserId === null ? false : true,
            };

        return this.apiService.post<any>(APIType.SportsbookBetting, 'InsertCoupon', bodyData, apiSettings).pipe(
          map(responseData => {
            let couponPosted = false;
            if (responseData.ResponseStatus === 1) {
              couponPosted = true;
            }

            // Clear booked coupon code after posting coupon
            this.couponQuery.couponSettings.bookedCouponCode &&
              this.couponStore.updateCouponSettings({ ...this.couponQuery.couponSettings, bookedCouponCode: null });

            return {
              success: couponPosted,
              statusCode: responseData.ResponseStatus,
              couponId: responseData.CouponId,
              couponCode: responseData.CouponCode,
              couponDate: responseData.CouponDate,
              updatedBetCoupon: responseData.UpdatedBetCoupon,
              bookingCode: responseData.BookingCode,
            };
          }),
          catchError(() =>
            of({
              success: false,
            })
          )
        );
      })
    );
  }

  private bookCoupon(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    const requestTransactionId = this.clientsideCouponService.generateTransactionId(); // TODO: don't regenerate every time
    const bodyData = this.couponQuery.couponData;

    return this.apiService.post<any>(APIType.SportsbookBetting2, `Book/${requestTransactionId}`, bodyData, apiSettings).pipe(
      map(responseData => {
        let couponBooked = false;
        if (responseData.ResponseStatus === 1) {
          couponBooked = true;
        }

        return {
          success: couponBooked,
          statusCode: responseData.ResponseStatus,
          couponCode: responseData.BookedCouponCode,
        };
      })
    );
  }

  private addBetSubmittedEvent(couponData, isQuickCoupon: boolean): void {
    const odds = couponData.Odds;
    const category = odds.reduce(BetslipSports, odds[0].SportName);
    const subCategory = odds.reduce(BetslipTournaments, odds[0].TournamentName);
    const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0].EventCategory)];
    // Need to resolve string not object, have to use native storage API as part of hotfix
    const bookingCode = localStorage.getItem(`n_${BOOKING_CODE_KEY}`) || undefined;
    const eventObj = {
      event: BetslipActions.BetSubmitted,
      user_id: this.accountQuery.userData?.id,
      currency: this.accountQuery.userData.currency.name,
      bet_type: isQuickCoupon ? 'Single_quick_bet' : CouponType[couponData.CouponType],
      selections: odds.length,
      is_free_bet: Boolean(couponData.BetDetails?.FreeBetDetails?.Code),
      voucher_code: couponData.BetDetails?.FreeBetDetails?.Code,
      cut_selections: couponData.BetDetails?.FlexiCutDetails?.Cut,
      sub_category: subCategory,
      product,
      category,
      allow_odds_change: this.couponQuery.couponSettings?.allowOddChanges,
      show_competition_name: this.couponQuery.couponSettings?.allowCompetitionGrouping,
      remember_last_stake: this.couponQuery.defaultCouponStake?.allowSaveDefault,
      dynamic_betbuilder_selections: this.couponQuery.dynamicBetBuilderSelectionsCount,
      precanned_betbuilder_selections: this.couponQuery.precannedBetBuilderSelectionsCount,
      booking_code: bookingCode,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }

  private sendBetslipErrorEvent(errorMessage): void {
    if (this.couponQuery?.couponData) {
      const odds = this.couponQuery.couponData?.Odds || [];
      const category = odds.reduce(BetslipSports, odds[0].SportName);
      const subCategory = odds.reduce(BetslipTournaments, odds[0].TournamentName);
      const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0].EventCategory)];

      this.dataLayerService.createDataLayerEvent({
        event: BetslipActions.BetslipError,
        user_id: this.accountQuery.userData?.id,
        error_message: errorMessage,
        sub_category: subCategory,
        product,
        category,
      });
    }
  }

  private sendBetSuccessEvent(response): void {
    const odds = response.updatedBetCoupon.Odds;
    const category = odds.reduce(BetslipSports, odds[0].SportName);
    const subCategory = odds.reduce(BetslipTournaments, odds[0].TournamentName);
    const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0].EventCategory)];
    // Need to resolve string not object, have to use native storage API as part of hotfix
    const bookingCode = localStorage.getItem(`n_${BOOKING_CODE_KEY}`) || undefined;

    const eventObj = {
      user_id: this.accountQuery.userData?.id,
      currency: this.accountQuery.userData.currency.name,
      coupon_id: response.couponCode,
      bet_stake: response.updatedBetCoupon.StakeGross,
      bet_type: CouponType[response.updatedBetCoupon.CouponTypeId],
      selections: odds.length,
      is_free_bet: Boolean(response.updatedBetCoupon.BetDetails?.FreeBetDetails?.Code),
      voucher_code: response.updatedBetCoupon.BetDetails?.FreeBetDetails?.Code,
      cut_selections: response.updatedBetCoupon.BetDetails?.FlexiCutDetails?.Cut,
      sub_category: subCategory,
      product,
      category,
      allow_odds_change: this.couponQuery.couponSettings?.allowOddChanges,
      show_competition_name: this.couponQuery.couponSettings?.allowCompetitionGrouping,
      remember_last_stake: this.couponQuery.defaultCouponStake?.allowSaveDefault,
      dynamic_betbuilder_selections: this.couponQuery.dynamicBetBuilderSelectionsCount,
      precanned_betbuilder_selections: this.couponQuery.precannedBetBuilderSelectionsCount,
      booking_code: bookingCode,
    };

    const firstBetPlaced = this.cookieService.getCookie('firstbetplaced');

    const adjustEventId = firstBetPlaced === 'false' ? AdjustEvents.FirstBetSuccess : AdjustEvents.BetSuccess;

    this.dataLayerService.createAdjustDataLayerEvent(adjustEventId, {
      user_id: eventObj.user_id,
      bet_stake: eventObj.bet_stake,
      product,
      bet_type: eventObj.bet_type,
      is_free_bet: eventObj.is_free_bet,
      currency: eventObj.currency,
    });

    // GTM
    this.dataLayerService.createDataLayerEvent({ ...eventObj, event: BetslipActions.BetSuccess });

    // Firebase App Event
    const fbEventId = firstBetPlaced === 'false' ? FirebaseEvent.FTBSuccess : FirebaseEvent.BetSuccess;
    const additionalParams = {
      userID: eventObj.user_id || '',
      ...eventObj,
    };
    this.dataLayerService.logFirebaseEvent(fbEventId, additionalParams);

    // VWO
    this.abTestService.track('live-bet-widget-v2', 'gtm.bet_success', { eventProperties: eventObj }).subscribe();
  }

  private sendBetFailureEvent(response, errorMessage): void {
    const couponData = response.updatedBetCoupon || this.couponQuery.couponData;
    const odds = couponData?.Odds || [];
    const category = odds.reduce(BetslipSports, odds[0].SportName);
    const subCategory = odds.reduce(BetslipTournaments, odds[0].TournamentName);
    const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0].EventCategory)];
    // Need to resolve string not object, have to use native storage API as part of hotfix
    const bookingCode = localStorage.getItem(`n_${BOOKING_CODE_KEY}`) || undefined;

    const eventObj = {
      event: BetslipActions.BetFailure,
      userID: this.accountQuery.userData?.id,
      currency: this.accountQuery.userData.currency.name,
      betStake: couponData.StakeGross,
      betType: CouponType[couponData.CouponTypeId],
      selections: odds.length,
      isFreeBet: Boolean(couponData.BetDetails?.FreeBetDetails?.Code),
      voucherCode: couponData.BetDetails?.FreeBetDetails?.Code,
      cut_selections: couponData.BetDetails?.FlexiCutDetails?.Cut,
      sub_category: subCategory,
      error_message: errorMessage,
      product,
      category,
      allow_odds_change: this.couponQuery.couponSettings?.allowOddChanges,
      show_competition_name: this.couponQuery.couponSettings?.allowCompetitionGrouping,
      remember_last_stake: this.couponQuery.defaultCouponStake?.allowSaveDefault,
      booking_code: bookingCode,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }

  sendOpenBetslipEvent(): void {
    const couponData = this.couponQuery.couponData;
    const odds = couponData?.Odds || [];
    const category = odds.reduce(BetslipSports, odds[0]?.SportName);
    const subCategory = odds.reduce(BetslipTournaments, odds[0]?.TournamentName);
    const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0]?.EventCategory)];
    const eventObj = {
      event: BetslipActions.BetslipOpen,
      user_id: this.accountQuery.userData?.id,
      sub_category: subCategory,
      product,
      category,
      allow_odds_change: this.couponQuery.couponSettings?.allowOddChanges,
      show_competition_name: this.couponQuery.couponSettings?.allowCompetitionGrouping,
      remember_last_stake: this.couponQuery.defaultCouponStake?.allowSaveDefault,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }

  private sendBookBetSuccessEvent(bookingCode: string): void {
    const couponData = this.couponQuery.couponData;

    const odds = couponData.Odds;
    const category = odds.reduce(BetslipSports, odds[0].SportName);
    const subCategory = odds.reduce(BetslipTournaments, odds[0].TournamentName);
    const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0].EventCategory)];

    const eventObj = {
      event: BetslipActions.BookBetSuccess,
      user_id: this.accountQuery.userData?.id,
      currency: this.accountQuery.userData?.currency.name,
      bet_stake: couponData.StakeGross,
      bet_type: CouponType[couponData.CouponTypeId],
      booking_code: bookingCode,
      selections: odds.length,
      product,
      category,
      sub_category: subCategory,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }

  private sendBookBetFailureEvent(errorMessage): void {
    const couponData = this.couponQuery.couponData;

    const odds = couponData.Odds;
    const category = odds.reduce(BetslipSports, odds[0].SportName);
    const subCategory = odds.reduce(BetslipTournaments, odds[0].TournamentName);
    const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0].EventCategory)];

    const eventObj = {
      event: BetslipActions.BookBetFailure,
      user_id: this.accountQuery.userData?.id,
      currency: this.accountQuery.userData?.currency.name,
      bet_stake: couponData.StakeGross,
      bet_type: CouponType[couponData.CouponTypeId],
      error_message: errorMessage,
      selections: odds.length,
      product,
      category,
      sub_category: subCategory,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }
}
