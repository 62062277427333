import { NgModule } from '@angular/core';
import { MobileVirtualsSoccerSharedModule } from '@kingmakers-tech/mobile-virtuals-soccer';
import { SharedModule } from 'src/app/shared/shared.module';
import { CouponModule } from 'src/app/modules/coupon/coupon.module';
import { VirtualsWidgetsModule } from 'src/app/modules/virtuals-widgets/virtuals-widgets.module';
import { VirtualsInstantCouponCheckboxComponent } from './components/virtuals-instant-coupon-checkbox/virtuals-instant-coupon-checkbox.component';
import { VirtualsInstantCouponRoutingModule } from './virtuals-instant-coupon-routing.module';
import { VirtualsInstantCouponComponent } from './virtuals-instant-coupon.component';
import { VirtualsInstantCouponGroupingsComponent } from './components/virtuals-instant-coupon-groupings/virtuals-instant-coupon-groupings.component';
import { VirtualsInstantCouponPageWrapperComponent } from './components/virtuals-instant-coupon-page-wrapper/virtuals-instant-coupon-page-wrapper.component';
import { VirtualsInstantCouponSelectionsComponent } from './components/virtuals-instant-coupon-selections/virtuals-instant-coupon-selections.component';
import { VirtualsInstantCouponTotalsComponent } from './components/virtuals-instant-coupon-totals/virtuals-instant-coupon-totals.component';
import { VirtualsInstantCouponSelectionItemComponent } from './components/virtuals-instant-coupon-selections/virtuals-instant-coupon-selection-item.component';

const COMPONENTS = [
  VirtualsInstantCouponComponent,
  VirtualsInstantCouponSelectionsComponent,
  VirtualsInstantCouponGroupingsComponent,
  VirtualsInstantCouponTotalsComponent,
  VirtualsInstantCouponPageWrapperComponent,
  VirtualsInstantCouponSelectionItemComponent,
  VirtualsInstantCouponCheckboxComponent,
];

@NgModule({
  imports: [SharedModule, VirtualsInstantCouponRoutingModule, VirtualsWidgetsModule, MobileVirtualsSoccerSharedModule, CouponModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  // Disabled the MvsOddsService provider because it was causing issues with changes related to VIR-8037 and
  // through testing it didn't seem to be used. Should be re-enabled if issues are encountered with the coupon
  // providers: [{ provide: MvsOddsService, useClass: VirtualsInstantCouponEditService }],
})
export class VirtualsInstantCouponModule {}
